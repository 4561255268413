import { Component, Vue, Watch } from 'vue-property-decorator'
import { AdminService } from '@/App/Services/Admin.service'
import VueJsonPretty from 'vue-json-pretty'
import dayjs from 'dayjs'
import { IntervalPicker } from '@/App/Components/Pickers'
import { SMTPLog } from '@/App/Services/interfaces/admin.interface'

@Component({
  components: { VueJsonPretty, IntervalPicker }
})
export default class SMTPLogsPage extends Vue {
  public isLoading = false
  public search = ''
  public headers = [
    { text: 'Info', align: 'left', sortable: true, value: 'info' },
    { text: 'Success', align: 'left', sortable: true, value: 'success' },
    { text: 'Date', align: 'left', sortable: true, value: 'dtCreate' }
  ]
  public logs: SMTPLog[] = []
  public dateInterval = {
    from: dayjs().startOf('day').unix(),
    to: dayjs().endOf('day').unix()
  }

  public toJSON(val: any): string {
    try {
      return JSON.parse(val)
    } catch {
      return val
    }
  }

  @Watch('dateInterval')
  private DateIntervalChanged(): void {
    this.fetchSMTPLogs()
  }

  private mounted() {
    this.fetchSMTPLogs()
  }

  private async fetchSMTPLogs(): Promise<void> {
    try {
      this.isLoading = true

      this.logs = await AdminService.fetchSMTPLogs(this.dateInterval.from, this.dateInterval.to)
    } catch {} finally {
      this.isLoading = false
    }
  }
}
